.center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

h6.user_welcoming {
    text-transform: capitalize;
    margin-top: .7rem;
    margin-bottom: .5rem;
    font-size: .85rem;
}

div.Home {
    height: 100vh;
    background-color: azure;
}

@media only screen and (max-width: 820px) {
    div.Home .row-tab-md-lg {
        display: none;
    }

}

@media only screen and (min-width: 821px) and (max-width: 1920px) {
    div.Home .row-tab-xs-xl {
        display: none;
    }
}

div.Home .card-body h4,
p {
    text-align: center;
    text-transform: none;
}


div.Home .card-body .fade.tab-pane {
    display: flex;
}

@media only screen and (max-width: 820px) {
    div.Home img.profile_img,
    div.Business img.profile_img {
        width: 70px;
        height: auto;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1920px) {

    div.Home img.profile_img,
    div.Business img.profile_img {
        width: 136px;
        height: auto;
    }
}



div.Home .business_form .card-body form img {
    height: 120px;
    width: auto;
}

.Home .nav-item a.nav-link {
    color: #a2a8b2;
    background-color: white;
    border-radius: 3px;
    border-color: #c9cfd9;
    border-style: ridge;

}

.Home .nav-item a.nav-link.active {
    color: white;
    background-color: #c9cfd9;
}

.Home .row-tab-xs-xl .responsive .nav-item .nav-link {
    color: #c9cfd9;
    background-color: white;
    border-radius: 3px;
    border-color: #c9cfd9;
    padding: 3px;
}

.Home .row-tab-xs-xl .responsive .nav-item .nav-link.active {
    color: white;
    background-color: #c9cfd9;
}

.Home .row-tab-xs-xl .row.responsive-content {
    background: white;
    border-style: groove;
    border-radius: 12px;
    border-color: #c9cfd9;
}

.Home .nav-item a.nav-link.facebook {
    color: #0d6efd;
    background-color: white;
    border-radius: 3px;
    border-color: #0d6efd;
    border-style: ridge;

}

.Home .nav-item a.nav-link.instagram {
    color: #e86aed;
    background-color: white;
    border-radius: 3px;
    border-color: #e86aed;
    border-style: ridge;

}

.Home .nav-item a.nav-link.twitter {
    color: #44d2f5;
    background-color: white;
    border-radius: 3px;
    border-color: #44d2f5;
    border-style: ridge;

}

.Home .nav-item a.nav-link.facebook.active {
    color: white;
    background-color: #5f9af1;
}

.Home .nav-item a.nav-link.instagram.active {
    color: white;
    background-color: #e86aed;
}

.Home .nav-item a.nav-link.twitter.active {
    color: white;
    background-color: #44d2f5;
}


.Home-col-tab .socialM-content {
    height: 80vh;
    background-color: #f6f8fa;
    border-radius: 5px;
    border-color: #85b2f5;
    border-style: ridge;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Home-col-tab .socialM-content .card {

    border: 3px solid #5f9af1;
}

.Home-col-tab .socialM-content .card .card-title {
    background: #5f9af1;
    padding-bottom: 7px;
    padding-left: 7px;

}

.Home-col-tab .socialM-content .card.inst {

    border: 3px solid #e86aed;
}

.Home-col-tab .socialM-content .card.inst .btn-instagram {

    color: #fff;
    background-color: #e86aca;
    border-color: #e86abe;
}

.Home-col-tab .socialM-content .card.inst .btn-instagram:hover {

    color: #fff;
    background-color: #e86abe;
    border-color: #a529be;
}



.Home-col-tab .socialM-content .card .card-title.inst {
    background: #e86aed;
    padding-bottom: 7px;
    padding-left: 7px;

}

.Home-col-tab .socialM-content .card .card-title a {
    color: #f9fafb;
    text-decoration: none;

}

@media only screen and (max-width: 820px) {
    .Home-col-tab .socialM-content .card .card-body img {
        width: 250px;
        height: auto;
    }

    .Home-col-tab .socialM-content .card .card-body video {
        width: 250px;
        height: auto;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1920px) {
    .Home-col-tab .socialM-content .card .card-body img {
        width: 225px;
        height: auto;
    }

    .Home-col-tab .socialM-content .card .card-body video {
        width: 225px;
        height: auto;
    }
}

.Home .nav-pills .nav-link {
    border: 1px;
    margin: 2px;
}

.modal-dialog .modal-header {
    background-color: #0d6efd;
}


.modal-header .modal-title {
    color: white;
}

.modal-content>.modal-post .modal-body img {

    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: auto;
    max-width: 250px;
}

.modal-content>.modal-post .modal-body label.danger.show{
    color: #fe2222;
    display: inline;
}

.modal-content>.modal-post .modal-body label.danger.hide{
    display: none;
}

.modal-content>.modal-post .modal-body video {

    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: auto;
    max-width: 250px;
}

.posts.offcanvas .offcanvas-header{
    background-color: #0d6efd;
    padding-top: 7px;
    padding-bottom: 7px;
}

.posts.offcanvas .instagram.offcanvas-header{
    background-color: #e86aed;
}

.posts.offcanvas .offcanvas-header .offcanvas-title a{
    color: white;
    text-decoration: none;
}


.posts.offcanvas .offcanvas-body p{
    text-align: justify;
}

.posts.offcanvas .offcanvas-body .offcanvas-img{
    /*text-align: center;*/
    height: auto;
    width: 300px 
}
