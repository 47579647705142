.center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

div.About {
    height: 100vh;
    z-index: -1;
}

@keyframes move-about{
    0% { opacity: 0; left: 0px; top: -135px;}
    100% {opacity: 1; left: 0px; top: 0px;}
}

div.About .container.about{
    position: relative;
    padding-top: 135px;
    animation: 
    move-about 4s;
}

div.About .container.about h3.title-about {
    color: #eeeeee;
}

div.About .container.about p.text-about {
    color: #eeeeee;
}