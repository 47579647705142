.center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

div.Inbox {
    height: 100vh;
    background-color: azure;
}

/*div.Inbox .messages .nav-item .nav-link {
    color: #c9cfd9;
    background-color: white;
    border-radius: 3px;
    border-color: #c9cfd9;
   
}*/


div.Inbox .messages .nav-item a.nav-link {
    color: #a2a8b2;
    background-color: white;
    border-radius: 3px;
    border-color: #c9cfd9;
    border-style: ridge;
    padding: 3px;
    margin-bottom: 3px;

}

.Inbox .nav-item a.nav-link {
    color: #a2a8b2;
    background-color: white;
    border-radius: 3px;
    border-color: #c9cfd9;
    border-style: ridge;
    margin-bottom: 5px;

}

.Inbox .nav-item a.nav-link.active {
    color: white;
    background-color: #5f9af1;
}

.Inbox .Inbox-col-tab .messages-content {
    height: 80vh;
    background-color: #f6f8fa;
    border-radius: 5px;
    border-color: #85b2f5;
    border-style: ridge;
    /*overflow-y: scroll;
    //overflow-x: hidden;*/
}

.Inbox .Inbox-col-tab .messages-content .fade.tab-pane {
    display: none;
}

.Inbox .Inbox-col-tab .messages-content .fade.tab-pane.show {
    display: inline;
}


.container-message {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* Para ocupar toda la altura de la ventana del navegador */
}

.container-message .row-80 {
    flex: 0 0 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.container-message .row-20 {
    flex: 0 0 15vh;
}


/* Clase para mostrar en tamaños MD a LG */
.nav-item.md-lg {
    display: block; /* Mostrar el contenido */
}

@media (max-width: 991px) { /* MD y menores */
    .nav-item.md-lg {
        display: none; /* Ocultar en XS y SM */
    }
}

/* Clase para mostrar en tamaños XS a SM */
.nav-item.xs-sm {
    display: none; /* Ocultar el contenido por defecto */
}

.nav-item.xs-sm a.nav-link {
    border-radius: 22px; /* Ajusta el valor según el nivel de redondeo que prefieras */
    margin-right: 25px; /* Opcional: añade algo de espacio interno */
   /* background-color: #f0f0f0; /* Opcional: color de fondo para resaltar */
    border: 1px solid #ddd; /* Opcional: borde para dar un efecto visual */
}

@media (max-width: 767px) { /* XS y SM */
    .nav-item.xs-sm {
        display: block; /* Mostrar en XS y SM */
    }
}