nav.Ali-navbar{
    z-index: 2;
}

nav.Ali-navbar .container a.navbar-brand img{
    height: 57px;
    width: auto;
    margin-top: -9px;
    margin-bottom: -9px;
}

.navbar-item {
    text-transform: capitalize;
}

Nav .me-auto .my-2 .my-lg-2 {
    max-height: 100px;
}

Nav .navbar-nav .dropdown-menu.show{
    background-color: #f0ffff;
}