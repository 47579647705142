.center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

div.Welcome {
    height: 100vh;
    z-index: -1;
}

@keyframes move-welcome{
    0% { opacity: 0; left: 0px; top: -135px;}
    100% {opacity: 1; left: 0px; top: 0px;}
}

div.Welcome .container {
    position: relative;
    padding-top: 300px;
    animation: 
    move-welcome 4s;
}

div.Welcome .container .card-welcome {
    margin-top: 25px;
    background-color: transparent;
    border-radius: 8px;
    border-color: #0d6efd;
    border-width: 4px;
}

div.Welcome .container img{
    width: 120px;
    height: auto;
    margin-top: -5px;
    margin-bottom: -5px;
}

div.Welcome .container .img-welcome {
    height: 255px;
    width: auto;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    div.Welcome .container .img-welcome {
        animation: App-logo-spin infinite 20s linear;
    }
}

div.Welcome .container h3.title-welcome {
    color: #eeeeee;
}

div.Welcome .container p.text-welcome {
    color: #eeeeee;
}

div.Welcome .container a.text-welcome {
    color: #eeeeee;
}