div.settings {
    height: 100vh;
    background-color: azure;
}


.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

/*//////////////////////General Settings //////////////////////////////////*/
.settings .nav-item a.nav-link {
    color: #0d6efd;
    background-color: white;
    border-radius: 3px;
    border-color: #0d6efd;
    border-style: ridge;

}

.settings .nav-item a.nav-link.active {
    color: white;
    background-color: #5f9af1;

}


.settings .nav-pills .nav-link {
    border: 1px;
    margin: 2px;
}

.settings .settings-content img {
    height: 75px;
    width: auto;
}

.settings .settings-content .business-list img {
    height: 120px;
    width: auto;
}

/*Modal update Picture */

.settings-business.modal .modal-content .modal-body img {
    height: 120px;
    width: auto;
}

.settings-business.modal .modal-content .modal-body img.afterChange {
    height: 70px;
    width: auto;
}

.settings-business.modal .modal-content .modal-body img.hide{
    display: none;
}
/*                         */

.settings .settings-content .btn-primary {
    margin-top: 5px;
}

.settings .settings-col-tab .settings-content {
    height: auto;
    background-color: #f6f8faef;
    border-radius: 5px;
    border-color: #85b2f5;
    border-style: ridge;
}

.settings .settings-col-tab .settings-content p {
    text-transform: capitalize;
    margin: auto;
}


.settings .settings-col-tab .lightbox,
.lightbox1,
.lightbox2 {
    transition: 0.3s all;
    opacity: 1;
    pointer-events: auto;
}

.settings .settings-col-tab .hide-lightbox,
.hide-lightbox1,
.hide-lightbox2 {
    display: none;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}

.settings .settings-col-tab .lightbox2 .alertpass,
.errorSuccess {
    transition: 0.3s all;
    opacity: 1;
    pointer-events: auto;
}

.settings .settings-col-tab .lightbox2 .hide-alertpass,
.hide-errorSuccess {
    display: none;
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}

/*///////////////////////////////////Business Tab///////////////////////////////////////////////*/

.settings .settings-col-tab .settings-content .profile-list,
.socialn-list,
.business-list {
    margin-top: 25px;
    margin-bottom: 25px;
}

.settings .settings-col-tab .settings-content .business-list h6,
.settings .settings-col-tab .settings-content .business-list p {
    display: inline;
}

.settings .settings-col-tab .settings-content .business-list .form-label {
    text-align: center;
}

/*//////////////////////////////////Profile Tab////////////////////////////////////////////////////////*/
.settings .settings-col-tab .settings-content .profile-list input {
    text-transform: capitalize;
}

/*//////////////////////////////////Social Tab/////////////////////////////////////////////////////////*/

.settings .settings-col-tab .settings-content .socialn-list button.show-button {
    display: inline;
}

.settings .settings-col-tab .settings-content .socialn-list button.hide-button {
    display: none;
}